import React from 'react';
import { Button, Box, Chip } from '@mui/material';
import { Colors } from 'constants/colors';
import {
  ExtraCellsObject,
  TableComponent,
} from 'components/table/table-component/table-component';
import { ReactComponent as GitHubIcon } from 'assets/icons/github.svg';
import { tableStructure } from './constants';
import {
  ExternalPackagesAdvisoryItem,
  OpenSourceDependenciesItemRes,
} from 'store/apis/types';
import { ReactComponent as HighSeverityIcon } from 'assets/icons/severity-high.svg';
import { ReactComponent as MediumSeverityIcon } from 'assets/icons/severity-medium.svg';
import { ReactComponent as LowSeverityIcon } from 'assets/icons/severity-low.svg';
import { ReactComponent as CriticalSeverityIcon } from 'assets/icons/severity-critical.svg';
import { OPENSOURCE_DETAILS_PACKAGE_ADVISORIES_TABLE } from 'constants/test-ids';

export const PackageAdvisoryModal = ({
  data,
}: {
  data: OpenSourceDependenciesItemRes | undefined;
}) => {
  const renderSeverityCell = (advisory: ExternalPackagesAdvisoryItem) => {
    let chipColor = '',
      chipBackground = '',
      chipIcon: JSX.Element;
    switch (advisory.severity) {
      case 'CRITICAL':
        chipColor = Colors.critical;
        chipBackground = Colors.criticalBg;
        chipIcon = (
          <CriticalSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'HIGH':
        chipColor = Colors.high;
        chipBackground = Colors.highBg;
        chipIcon = (
          <HighSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'MODERATE':
        chipColor = Colors.medium;
        chipBackground = Colors.mediumBg;
        chipIcon = (
          <MediumSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
      case 'LOW':
        chipColor = Colors.low;
        chipBackground = Colors.lowBg;
        chipIcon = (
          <LowSeverityIcon
            style={{
              width: '16px',
              height: '16px',
            }}
          />
        );
        break;
    }
    return (
      <Chip
        size="small"
        sx={{
          backgroundColor: chipBackground,
          color: chipColor,
          border: `1.8px solid ${chipBackground}`,
          px: 1,
        }}
        icon={chipIcon}
        label={advisory.severity}
        variant="outlined"
      />
    );
  };

  const renderPackageCell = (advisory: ExternalPackagesAdvisoryItem) => {
    return (
      <Button
        startIcon={
          <GitHubIcon
            style={{
              width: '16px',
              height: '16px',
              color: Colors.binge,
            }}
          />
        }
        onClick={() =>
          data?.repository_url
            ? window.open(data?.repository_url, '_blank', 'noreferrer')
            : null
        }
        sx={{
          color: Colors.darkGray,
          fontSize: '8px',
          '&:hover': {
            backgroundColor: 'transparent',
            color: Colors.darkGray,
          },
        }}
      >
        {advisory.package}
      </Button>
    );
  };

  const extraCellsRenderObj: ExtraCellsObject = {
    package: renderPackageCell,
    severity: renderSeverityCell,
  };

  return (
    <Box height="auto" width="60vw">
      <>
        {data?.advisories && (
          <TableComponent
            tableId={OPENSOURCE_DETAILS_PACKAGE_ADVISORIES_TABLE}
            tableHeight="auto"
            isListLoading={false}
            isListErrored={false}
            isEmptyList={false}
            isListFetching={false}
            dataTestId={OPENSOURCE_DETAILS_PACKAGE_ADVISORIES_TABLE}
            list={data.advisories.map((item) => {
              return {
                ...item,
                versions: `${item.introduced} - ${item.last_affected}`,
              };
            })}
            tableColumns={tableStructure}
            extraCellsRenderObj={extraCellsRenderObj}
            notPaginated
          />
        )}
      </>
    </Box>
  );
};
