import { BranchesMultiFilter } from 'pages/branches/components/branches-multi-filter/branches-multi-filter';
import {
  IdentitiesFilterList,
  IdentitiesFilters,
} from 'pages/identities/components/identities-multi-filter/constants';
import { tableStructureWithDisplayName as identitesTableStructure } from 'pages/identities/constants';
import {
  ArtifactFilterList,
  AssetFilterList,
} from 'pages/assets/components/assets-multi-filter/constants';
import { tableStructureWithOrg as assetsTableStructure } from 'pages/assets/constants';
import { TeamsFilterList } from 'pages/teams/components/teams-multi-filter/constants';
import { tableStructureWithOrg as teamsTableStructure } from 'pages/teams/constants';
import { tableStructure } from 'pages/secrets/constants';
import { tableStructure as reposWithAccessTableStructure } from 'pages/opensource-dependencies/opensource-dependency-details/components/repositories/constants';
import { OpenSourceTableStructure } from 'pages/opensource-dependencies/constants';
import { GenericFilter } from 'components/filter/filter';
import {
  AssetContributorsFilterList,
  tableStructureWithLogin,
} from 'pages/assets/asset-details/components/list-of-contributors/constants';
import { useContext } from 'react';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import {
  IdentitiesAccessFilterList,
  accessTableStructureAdmin,
} from 'pages/identities/identity-details/components/access-table/constants';
import {
  OSSDetailsFilterList,
  OSSDetailsTableStructure,
} from 'pages/identities/identity-details/components/oss-details-table/constants';
import {
  unusedAccessFilterList,
  unusedAccessTableStructure,
} from 'pages/identities/identity-details/components/identity-recommendations/constants';
import {
  activityTableStructure,
  pipelineActivityFilterList,
} from 'pages/identities/identity-details/components/pipeline-activity-table/constants';
import {
  alertsFilterList,
  alertsTableStructure,
} from 'pages/identities/identity-details/components/alerts-table/constants';
import {
  DastFilterList,
  DastTableStructure,
} from 'pages/identities/identity-details/components/secret-details-table/constants';
import { SecretsFilterList } from 'pages/identities/identity-details/components/secret-details-table/constants';
import {
  customPolicyFilterList,
  policyFilterList,
  policyTableStructure,
} from 'pages/policies/components/policy-library/constant';
import { activityTableStructure as sourceCodeStructure } from 'pages/identities/identity-details/components/activity-table/constants';
import { tableStructure as externalPackagesStructure } from 'pages/assets/asset-details/components/external-packages/constants';
import { assetsTableStructure as teamReposTableStructure } from 'pages/teams/components/team-details/components/repos-with-access-table/constants';
import { customPolicyTableStructure } from 'pages/policies/components/custom-policies/constant';
import { membersTableStructure } from 'pages/teams/components/team-details/components/members-table/constants';
import { tableStructure as assetContainersStructure } from 'pages/assets/asset-details/components/containers/constants';
import { repositoriesTeamsTableStructure } from 'pages/assets/asset-details/components/list-of-teams/constants';
import {
  SastFilterList,
  tableStructure as sastTableStructure,
} from 'pages/sast-scan/constants';
import { tableStructure as iacTableStructure } from 'pages/iac-scan/constants';
import {
  setActiveSortedField,
  setActiveSortedOrder,
} from 'store/modules/selected-column/selected-column.reducer';
import { MembersFilters } from 'pages/teams/components/team-details/components/members-table/members-multi-filter/constants';
import { teamVulnerabilityScoreTableStructure } from 'pages/reports/constants';
import { OpenSourceFilterList } from 'pages/opensource-dependencies/components/opensource-dependency-multi-filter/constants';
import { useEditPolicyColumnsSelector } from 'store/modules/branches/branches.selector';
import { editPolicyFilters } from 'pages/edit-policy/constant';

export enum TableNamesOfFilters {
  IDENTITIES_USERS_LIST = 'identities_users_list',
  ASSETS_REPOS_LIST = 'assets_repos_list',
  ASSETS_ARTIFACTS_LIST = 'assets_artifacts_list',
  BRANCHES_LIST = 'branches_list',
  TEAMS_LIST = 'teams_list',
  IDENTITIES_ACCESS = 'identities_access',
  IDENTITIES_ACTIVITY = 'identities_activity',
  IDENTITIES_SECRETS_LIST = 'identities_secrets_list',
  IDENTITIES_DAST_LIST = 'identities_dast_list',
  ASSETS_ACTIVITY = 'assets_activity',
  IDENTITIES_PIPELINE = 'identities_pipeline',
  ASSETS_PIPELINE = 'assets_pipeline',
  ASSETS_DETAILS_CONTRIBUTORS = 'assets_details_contributors',
  ASSET_DETAILS_TEAMS = 'asset_details_teams',
  ASSETS_NESTED_BRANCHES = 'assets_nested_branches',
  ASSET_EXTERNAL_PACKAGES = 'asset_external_packages',
  ASSET_DETAILS_CONTAINERS = 'asset_details_containers',
  OPENSOURCE_REPOSITORIES_LIST = 'opensource-repositories_list',
  OSS_ACCESS_REPOS = 'oss-access-repos',
  DETECTED_SECRETS_LIST = 'detected_secrets_list',
  POLICY_BUILDER = 'policy-builder',
  POLICIES_LIBRARY = 'policies-library',
  POLICIES_CUSTOM_POLICIES = 'policies-custom-polices',
  JIT_PENDING_REQUESTS = 'jit_pending',
  RECOMMENDATIONS_LIST = 'recommendations_list',
  TEAM_REPO_ACCESS = 'team_repo_access',
  TEAM_MEMBERS_TABLE = 'team_members_table',
  EDIT_POLICY = 'edit-policy',
}

export const FilterRenderer = (props: any) => {
  const apiContext = useContext(ApiContext);

  const { data: connectedIntegrations } =
    apiContext.baseApi?.useGetAllCurrentConnectionsQuery();

  const connections =
    connectedIntegrations?.map((item) =>
      item.name === 'Github'
        ? 'GitHub'
        : item.name === 'Gitlab'
        ? 'GitLab'
        : item.name === 'Bitbucket'
        ? 'BitBucket'
        : item.name,
    ) || [];

  const { table, isLast } = props;

  const editPolicyColumns = useEditPolicyColumnsSelector();

  switch (table) {
    case 'identities_users_list':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={IdentitiesFilters(connections)}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={identitesTableStructure}
        />
      );
    case 'assets_repos_list':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={AssetFilterList(connections)}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={assetsTableStructure}
        />
      );
    case 'assets_artifacts_list':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={ArtifactFilterList(connections)}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={assetsTableStructure}
        />
      );
    case 'branches_list':
      return <BranchesMultiFilter tableId={table} />;
    case 'teams_list':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={TeamsFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={teamsTableStructure}
        />
      );
    case 'identities_access':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={IdentitiesAccessFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={accessTableStructureAdmin}
        />
      );
    case 'identities_pipeline':
    case 'assets_pipeline':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={pipelineActivityFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={activityTableStructure}
        />
      );
    case 'identities_activity':
    case 'assets_activity':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={[]}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={sourceCodeStructure}
        />
      );
    case 'identities_oss':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={OSSDetailsFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={OSSDetailsTableStructure}
        />
      );
    case 'identities_dast_list':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={DastFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={DastTableStructure}
        />
      );
    case 'identities_recommendations':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={unusedAccessFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={unusedAccessTableStructure}
        />
      );
    case 'assets_details_contributors':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={AssetContributorsFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={tableStructureWithLogin}
        />
      );
    case 'asset_details_teams':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={[]}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={repositoriesTeamsTableStructure}
        />
      );
    case 'asset_details_containers':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={[]}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={assetContainersStructure}
        />
      );
    case 'asset_external_packages':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={[]}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={externalPackagesStructure}
        />
      );
    case 'alerts_list':
    case 'identities-alerts':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={alertsFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={alertsTableStructure}
        />
      );
    case 'assets_nested_branches':
      return <BranchesMultiFilter tableId={table} />;
    case 'opensource-repositories_list':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={OpenSourceFilterList()}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={OpenSourceTableStructure}
        />
      );
    case 'detected_secrets_list':
    case 'asset-details-secrets':
    case 'identities_secrets_list':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={SecretsFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={tableStructure}
        />
      );
    case 'sast_findings':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={SastFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={sastTableStructure}
        />
      );
    case 'dast_findings':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={DastFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={DastTableStructure}
        />
      );
    case 'iac_findings':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={[]}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={iacTableStructure}
        />
      );
    case 'policy-builder':
      return <></>;
    case 'policies-library':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={policyFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={policyTableStructure}
        />
      );
    case 'policies-custom-polices':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={customPolicyFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={customPolicyTableStructure}
        />
      );
    case 'recommendations_list':
      return <></>;
    case 'team_repo_access':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={MembersFilters()}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={teamReposTableStructure}
        />
      );
    case 'team_members_table':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={[]}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={membersTableStructure}
        />
      );
    case 'recommendation-teams-table':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={[]}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={teamVulnerabilityScoreTableStructure}
        />
      );
    case 'opensource-details-repositories-table':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={[]}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={reposWithAccessTableStructure}
        />
      );
    case 'edit-policy':
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={editPolicyFilters(connections)}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={editPolicyColumns}
        />
      );
    default:
      return (
        <GenericFilter
          isLast={isLast}
          tableId={table}
          FilterList={IdentitiesFilterList}
          setActiveSortedField={setActiveSortedField}
          setActiveSortedOrder={setActiveSortedOrder}
          tableStructure={identitesTableStructure}
        />
      );
  }
};
