import { TableColumnProps } from 'components/table/table-component/table-component';
import { useAppSelector } from '../../hooks';
import { SortOrder } from 'utils/sort-order/sort';

export const useActiveSortedFieldSelector = (): string =>
  useAppSelector(({ branches }) => branches.activeSortedField);

export const useActiveSortedOrderSelector = (): SortOrder =>
  useAppSelector(({ branches }) => branches.activeSortedOrder);

export const usePoliciesActiveTabSelector = (): number =>
  useAppSelector(({ branches }) => branches.policiesActiveTab);

export const useActivePolicySeveritySelector = (): string =>
  useAppSelector(({ branches }) => branches.activePolicySeverity);

export const useActiveCustomStatusSelector = (): string =>
  useAppSelector(({ branches }) => branches.activeCustomStatus);

export const useWithDetectionsSelector = (): boolean =>
  useAppSelector(({ branches }) => branches.withDetections);

export const useExpandedRowSelector = (): number | undefined =>
  useAppSelector(({ branches }) => branches.expandedRow);

export const useEditPolicyColumnsSelector = (): TableColumnProps[] =>
  useAppSelector(({ branches }) => branches.editPolicyColumns);

export const useSidebarExpandedSelector = (): boolean =>
  useAppSelector(({ branches }) => branches.sideBarExpanded);

export const useCalendarOpenSelector = (): boolean =>
  useAppSelector(({ branches }) => branches.calendarOpen);
