import React from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { FilterChip } from './filter-chip';
import {
  resetDetectionsCount,
  resetSortState,
  setApplicableFilters,
  setFilterChips,
  setHighlightColumns,
  setSearchBy,
} from 'store/modules/selected-column/selected-column.reducer';
import { Colors } from 'constants/colors';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';

const fullFilterMapping: Record<string, string> = {
  internal_account: 'identity_status',
  external_account: 'identity_status',
  unknown: 'identity_status',
};

interface props {
  tableId: string;
}

export const FilterChips = ({ tableId }: props) => {
  const dispatch = useDispatch();
  const {
    applicableFilters,
    tableFilterChips,
    highlightColumns,
    detectionsCount: { from, to },
    searchBy,
  } = useSelectedColumnSelector(tableId);

  const {
    sorting_field,
    sorting_order,
    search_query,
    search_field,
    searchChips,
    filterTitles,
    ...filterChips
  } = tableFilterChips;
  const removeFilter = (
    colId: string,
    column: string,
    sort?: boolean,
    search?: boolean,
  ) => {
    const currentChips = Object.assign(
      { ...filterChips },
      {
        filterTitles,
        sorting_field,
        sorting_order,
        search_field,
        search_query,
        searchChips,
      },
    );
    let mutableSearchChips = searchChips;
    if (!sort && !search) {
      if (typeof filterTitles === 'object' && filterTitles?.length) {
        const currentTitles = filterTitles.filter((item) => item?.id !== colId);
        currentChips.filterTitles = currentTitles;
      }
      delete currentChips[colId];
    }
    if (sort) {
      delete currentChips.sorting_field;
      delete currentChips.sorting_order;
      dispatch(resetSortState({ table: tableId }));
    }
    if (search) {
      if (Array.isArray(mutableSearchChips))
        mutableSearchChips = mutableSearchChips.filter(
          (chip) => chip.id !== colId,
        );
      dispatch(
        setSearchBy({
          table: tableId,
          searchBy: searchBy.filter((srch) => srch.id !== colId),
        }),
      );
    }
    dispatch(
      setFilterChips({
        table: tableId,
        tableFilterChips: { ...currentChips, searchChips: mutableSearchChips },
      }),
    );
    if (
      typeof filterTitles === 'object' &&
      filterTitles?.length &&
      filterTitles?.filter((item) => item?.column === column)?.length === 1
    ) {
      const currentColumns = highlightColumns.filter(
        (item) => item?.id !== column,
      );
      if (
        !sort &&
        !search &&
        column !== sorting_field &&
        column !== search_field
      )
        dispatch(
          setHighlightColumns({
            table: tableId,
            highlightColumns: [...currentColumns],
          }),
        );
    } else if (sort) {
      const currentColumns = highlightColumns.filter(
        (item) => item?.id !== column,
      );
      dispatch(
        setHighlightColumns({
          table: tableId,
          highlightColumns: [...currentColumns],
        }),
      );
    } else if (search) {
      const currentColumns = highlightColumns.filter(
        (item) => item?.id !== column,
      );
      dispatch(
        setHighlightColumns({
          table: tableId,
          highlightColumns: [...currentColumns],
        }),
      );
      const applyFilter = { ...applicableFilters };
      dispatch(
        setApplicableFilters({
          table: tableId,
          applicableFilters: {
            ...applyFilter,
            search_field: '',
            search_query: '',
          },
        }),
      );
    }
    if (!sort && !search) {
      const applyFilter = { ...applicableFilters };
      delete applyFilter[colId];
      Object.keys(applyFilter).forEach((id) => {
        if (fullFilterMapping[id] === colId) {
          delete applyFilter[id];
        }
      });
      dispatch(
        setApplicableFilters({
          table: tableId,
          applicableFilters: { ...applyFilter },
        }),
      );
    }
  };
  return (
    <>
      {sorting_field ? (
        <Box
          sx={{
            borderRight: `1px solid ${Colors.chipBorder}`,
          }}
        >
          <FilterChip
            filterName={'Sort By'}
            sortedColumn={
              highlightColumns.find((column) => column.id === sorting_field)
                ?.title || ''
            }
            filterValue={`${
              sorting_order === 'asc' ? 'Ascending' : 'Descending'
            }`}
            removeFilter={() =>
              removeFilter(
                sorting_field.toString(),
                sorting_field.toString(),
                true,
              )
            }
          />
        </Box>
      ) : null}
      {Array.isArray(searchChips) &&
        searchChips.map((srch) => (
          <FilterChip
            filterName={`${srch.title} Search`}
            filterValue={srch.query}
            removeFilter={() => {
              removeFilter(srch.id, srch.id, false, true);
            }}
          />
        ))}
      {typeof filterTitles === 'object' && !!filterTitles?.length && (
        <Box
          sx={{
            borderRight: `1px solid ${Colors.chipBorder}`,
            display: 'flex',
          }}
        >
          {filterTitles.map((col) => (
            <FilterChip
              key={col.id}
              filterName={col.title}
              filterValue={filterChips[col.id]?.toString()}
              removeFilter={() => removeFilter(col.id, col.column)}
            />
          ))}
        </Box>
      )}
      {(tableId === 'policies-library' ||
        tableId === 'policies-custom-polices') &&
        (to !== '0' || from !== '0') && (
          <FilterChip
            filterName={'Detections Count'}
            filterValue={`${from}-${to}`}
            removeFilter={() => {
              dispatch(
                resetDetectionsCount({
                  table: tableId,
                }),
              );
              if (applicableFilters.sorting_field !== 'count')
                dispatch(
                  setHighlightColumns({
                    table: tableId,
                    highlightColumns: highlightColumns.filter(
                      (col) => col.id !== 'count',
                    ),
                  }),
                );
            }}
          />
        )}
    </>
  );
};
