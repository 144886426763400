import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { TableColumnProps } from 'components/table/table-component/table-component';

import { SortOrder } from 'utils/sort-order/sort';

export interface BranchesState {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
  activePolicySeverity: string;
  activeCustomStatus: string;
  policiesActiveTab: number;
  withDetections: boolean;
  expandedRow: number | undefined;
  editPolicyColumns: TableColumnProps[];
  sideBarExpanded: boolean;
  calendarOpen: boolean;
}

const initialState: BranchesState = {
  activeSortedField: 'severity',
  activeSortedOrder: SortOrder.Desc,
  policiesActiveTab: 0,
  activeCustomStatus: 'all',
  activePolicySeverity: 'all',
  withDetections: false,
  expandedRow: -1,
  editPolicyColumns: [],
  sideBarExpanded: true,
  calendarOpen: true,
};

export const branchesSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    setActiveSortedField: (
      state: BranchesState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField =
        action.payload || initialState.activeSortedField;
    },
    setActiveSortedOrder: (
      state: BranchesState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
    setPoliciesActiveTab: (
      state: BranchesState,
      action: PayloadAction<number>,
    ) => {
      state.policiesActiveTab = action.payload;
    },
    setActivePolicySeverity: (
      state: BranchesState,
      action: PayloadAction<string>,
    ) => {
      state.activePolicySeverity = action.payload;
    },
    setActiveCustomStatus: (
      state: BranchesState,
      action: PayloadAction<string>,
    ) => {
      state.activeCustomStatus = action.payload;
    },
    setWithDetections: (
      state: BranchesState,
      action: PayloadAction<boolean>,
    ) => {
      state.withDetections = action.payload;
    },
    setExpandedRow: (
      state: BranchesState,
      action: PayloadAction<number | undefined>,
    ) => {
      state.expandedRow = action.payload;
    },
    setEditPolicyColumns: (
      state: BranchesState,
      action: PayloadAction<TableColumnProps[]>,
    ) => {
      state.editPolicyColumns = action.payload;
    },
    setSideBarExpanded: (
      state: BranchesState,
      action: PayloadAction<boolean>,
    ) => {
      state.sideBarExpanded = action.payload;
    },
    setCalendarOpen: (state: BranchesState, action: PayloadAction<boolean>) => {
      state.calendarOpen = action.payload;
    },
  },
});

export const {
  setActiveSortedField,
  setActiveSortedOrder,
  setPoliciesActiveTab,
  setActivePolicySeverity,
  setActiveCustomStatus,
  setWithDetections,
  setExpandedRow,
  setEditPolicyColumns,
  setSideBarExpanded,
  setCalendarOpen,
} = branchesSlice.actions;

export const branchesReducer = branchesSlice.reducer;
