import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { ToastMessage } from '../toast/toast-message';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { SelectChangeEvent, Typography } from '@mui/material';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import {
  VISIBILITY_HIDE_BUTTON,
  VISIBILITY_NONE_BUTTON,
  VISIBILITY_SHOW_BUTTON,
} from 'constants/test-ids';
import {
  StyledOptionItem,
  StyledSelect,
} from 'components/inputs/select/select';
import { CustomSvgIcon } from 'components/svg-icon/svg-icon';

interface Props {
  id: string;
  watched: boolean | null | undefined;
  hidden: boolean | null | undefined;
  switchWatched: MutationTrigger<any>;
  switchHidden: MutationTrigger<any>;
  disableTransform?: boolean;
  source?: any;
}

export const VisibilityButton = ({
  id,
  watched,
  hidden,
  switchWatched,
  switchHidden,
  source,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  const tabDetails = [
    {
      label: 'Hide',
      icon: (
        <VisibilityOff
          sx={{ width: '16px', height: '16px', color: Colors.darkGray }}
        />
      ),
      dataTestId: VISIBILITY_HIDE_BUTTON,
    },
    {
      label: 'None',
      icon: undefined,
      dataTestId: VISIBILITY_NONE_BUTTON,
    },
    {
      label: 'Show',
      icon: (
        <Visibility
          sx={{ width: '16px', height: '16px', color: Colors.darkGray }}
        />
      ),
      dataTestId: VISIBILITY_SHOW_BUTTON,
    },
  ];
  const getValue = (): number => {
    if (watched) return 2;
    if (hidden) return 0;
    else return 1;
  };

  const handleVisibility = (event: SelectChangeEvent) => {
    const value = parseInt(event.target.value);
    if (watched || value === 2) {
      try {
        // @ts-ignore
        source
          ? switchWatched({ id: id!, source: source })
          : switchWatched(id!);
      } catch (error) {
        toast(
          <ToastMessage type="error" title="Your request was unsuccessful" />,
        );
      }
    }
    if (hidden || value === 0) {
      try {
        source ? switchHidden({ id: id!, source: source }) : switchHidden(id!);
      } catch (error) {
        toast(
          <ToastMessage type="error" title="Your request was unsuccessful" />,
        );
      }
    }
  };

  return (
    <Box sx={{ gap: '5px' }}>
      <Typography
        sx={{
          color: Colors.darkGray,
          fontFamily: fontSecondary,
          fontSize: FontSizes.fontSize12,
          fontWeight: 500,
          mb: '5px',
        }}
      >
        Watch/Hide
      </Typography>
      <StyledSelect
        variant="outlined"
        color="info"
        size="medium"
        value={getValue()}
        onClose={() => setExpanded(false)}
        onOpen={() => setExpanded(true)}
        inputProps={{
          MenuProps: {
            PaperProps: {
              style: {
                marginTop: '5px',
              },
            },
          },
        }}
        renderValue={(opt: any) => {
          const label = opt === 0 ? 'Hide' : opt === 2 ? 'Show' : 'None';
          const icon = tabDetails.find((tab) => tab.label === label)?.icon;
          return (
            <>
              {icon && (
                <CustomSvgIcon
                  sx={{
                    fontSize: FontSizes.fontSize14,
                    width: '10px',
                    height: '10px',
                    marginRight: '6px',
                  }}
                >
                  {icon}
                </CustomSvgIcon>
              )}
              {label}
            </>
          );
        }}
        //@ts-ignore
        onChange={handleVisibility}
        sx={{
          minWidth: '120px',
          width: 'auto',
          '& .MuiSelect-outlined': {
            color: `${Colors.blackPearl} !important`,
            WebkitTextFillColor: `${Colors.blackPearl} !important`,
          },
          '&.MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
              fontSize: FontSizes.fontSize14,
              textFillColor: `${Colors.blackPearl}!important`,
            },
          },
          '& .MuiSelect-icon': {
            color: `${
              expanded ? Colors.blackPearl : Colors.darkGray
            } !important`,
            transition: 'color 0.3s',
          },
        }}
      >
        {tabDetails.map((tab, index) => (
          <StyledOptionItem
            value={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {tab.icon && (
              <CustomSvgIcon
                iconSize={FontSizes.fontSize14}
                sx={{
                  width: '14px',
                  height: '14px',
                  marginRight: '6px',
                }}
              >
                {tab.icon}
              </CustomSvgIcon>
            )}
            <Typography sx={{ fontSize: FontSizes.fontSize14 }}>
              {tab.label}
            </Typography>
          </StyledOptionItem>
        ))}
      </StyledSelect>
    </Box>
  );
};
