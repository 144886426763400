import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  // {
  //   id: 'expand',
  //   title: '',
  //   position: 'left',
  // },
  {
    id: 'type',
    title: 'Secret Type',
    withSort: true,
    noFilters: true,
    withSearch: true,
    position: 'left',
  },
  {
    id: 'created',
    title: 'Date',
    position: 'left',
    isDate: true,
  },
  {
    id: 'num_repos',
    title: 'Repositories',
    withSort: true,
    noFilters: true,
    withSearch: true,
    position: 'left',
  },
  {
    id: 'num_users',
    title: 'Users',
    withSort: true,
    noFilters: true,
    withSearch: true,
    position: 'left',
  },
  {
    id: 'verified',
    title: 'Verified',
    position: 'left',
  },
  {
    id: 'num_files',
    title: 'Files',
    position: 'left',
  },
  {
    id: 'options',
    title: '',
    position: 'left',
  },
];

// export const tableStructure: TableColumnProps[] = [
//   {
//     id: 'repository',
//     title: 'Asset',
//     withSort: true,
//     position: 'left',
//   },
//   {
//     id: 'rule_id',
//     title: 'Type',
//     withSort: true,
//     position: 'left',
//   },
//   {
//     id: 'scan_date',
//     title: 'Date',
//     withSort: true,
//     position: 'left',
//     isDate: true,
//   },
//   {
//     id: 'commit',
//     title: 'Committer',
//     withSort: true,
//     position: 'left',
//   },
//   {
//     id: 'organization',
//     title: 'Asset Visibility',
//     withSort: true,
//     position: 'left',
//   },
//   {
//     id: 'verified',
//     title: 'Verified',
//     withSort: true,
//     position: 'left',
//   },
//   {
//     id: 'status',
//     title: 'Status',
//     withSort: true,
//     position: 'left',
//   },
// ];
