import React, { ReactNode } from 'react';
import { Modal, Box, Typography, Divider } from '@mui/material';

import { Colors } from 'constants/colors';
import { StyledButton } from '../../button/button';
import { FontSizes } from '../../../constants/font-sizes';
import { fontPrimary } from 'constants/font-family';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-circle.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';

interface Props {
  children?: ReactNode;
  title?: string;
  titleSize?: string;
  subTitle?: string;
  alignItems?: string;
  handleCloseModal?: () => void;
  handleConfirm?: () => void;
  modalWidth?: string;
  minWidth?: string;
  modalHeight?: string;
  isOpenModal: boolean;
  closeModalTitle?: string;
  confirmModalTitle?: string;
  backgroundColor?: string;
  left?: string;
  top?: string;
  disableConfirm?: boolean;
  disableCancel?: boolean;
  removal?: boolean;
}

export const ModalContainer = ({
  children,
  title,
  titleSize,
  subTitle,
  alignItems,
  handleCloseModal,
  handleConfirm,
  isOpenModal,
  closeModalTitle,
  confirmModalTitle,
  modalHeight,
  modalWidth,
  minWidth,
  backgroundColor,
  left,
  top,
  disableConfirm,
  disableCancel = false,
  removal = false,
}: Props) => {
  return (
    <Modal onClose={handleCloseModal} open={isOpenModal}>
      <Box
        sx={{
          position: 'absolute',
          top: top || '15%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: modalWidth || '500px',
          minWidth: minWidth || '500px',
          boxShadow: 24,
          backgroundColor: backgroundColor || Colors.white,
          display: 'flex',
          flexDirection: 'column',
          alignItems: alignItems ? alignItems : 'center',
          padding: '20px 15px',
          borderRadius: '15px',
          maxHeight: modalHeight || '90vh',
          overflow: 'auto',
        }}
      >
        {!removal && (
          <Box width="100%" display="flex" justifyContent="space-between">
            <Box>
              <Box sx={{ textAlign: 'left', width: '100%' }}>
                {title && (
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: titleSize || FontSizes.fontSize20,
                      fontWeight: 600,
                      fontFamily: fontPrimary,
                      color: Colors.blackPearl,
                    }}
                  >
                    {title}
                  </Typography>
                )}
                {subTitle && (
                  <Typography
                    sx={{
                      fontSize: FontSizes.fontSize10,
                      color: Colors.darkGray,
                      fontFamily: fontPrimary,
                    }}
                  >
                    {subTitle}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
              {handleCloseModal && (
                <CloseIcon
                  onClick={handleCloseModal}
                  style={{
                    cursor: 'pointer',
                    width: '20px',
                    color: Colors.darkGray,
                  }}
                />
              )}
            </Box>
          </Box>
        )}

        {removal && (
          <Box width={'100%'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                cursor: 'pointer',
                marginBottom: '10px',
              }}
            >
              {handleCloseModal && (
                <CloseIcon
                  onClick={handleCloseModal}
                  style={{
                    cursor: 'pointer',
                    width: '20px',
                    color: Colors.darkGray,
                  }}
                />
              )}
            </Box>
            <Divider sx={{ color: Colors.darkGray, marginBottom: '20px' }} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                alignItems: 'center',
                padding: '20px',
                height: '200px',
                margin: '20px',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  '& svg path': {
                    fill: `${Colors.lightRed} !important`,
                  },
                }}
              >
                <DeleteIcon style={{ width: '50px', height: '50px' }} />
              </Box>
              <Box sx={{ textAlign: 'center', width: '60%' }}>
                {title && (
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: titleSize || FontSizes.fontSize20,
                      fontWeight: 600,
                      fontFamily: fontPrimary,
                      color: Colors.blackPearl,
                      lineHeight: titleSize || FontSizes.fontSize20,
                    }}
                  >
                    {title}
                  </Typography>
                )}
                {subTitle && (
                  <Typography
                    sx={{
                      fontSize: FontSizes.fontSize14,
                      color: Colors.darkGray,
                      fontWeight: 500,
                      lineHeight: FontSizes.fontSize21,
                      fontFamily: fontPrimary,
                      marginTop: '10px',
                    }}
                  >
                    {subTitle}
                  </Typography>
                )}
              </Box>
            </Box>
            <Divider sx={{ color: Colors.darkGray, marginTop: '20px' }} />
          </Box>
        )}
        {children}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: removal ? '10px' : 'auto',
            paddingTop: '15px',
            gap: '10px',
          }}
        >
          {handleCloseModal && !disableCancel && (
            <StyledButton
              onClick={handleCloseModal}
              variant="outlined"
              color="secondary"
              size="large"
            >
              {closeModalTitle ? closeModalTitle : 'Cancel'}
            </StyledButton>
          )}
          {handleConfirm && (
            <StyledButton
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
              size="large"
              disabled={disableConfirm}
              sx={{
                ...(removal
                  ? { background: `${Colors.lightRed} !important` }
                  : {}),
              }}
            >
              {confirmModalTitle ? confirmModalTitle : 'Confirm'}
            </StyledButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
