import { FilterItem } from 'components/multi-filter/multi-filter';

export const editPolicyFilters = (connections: string[]): FilterItem[] => {
  return [
    {
      title: 'Source',
      filterId: 'source',
      filterColumns: ['source'],
      props: connections
        .map((item) => ({ label: item, value: item }))
        .filter(
          (connector) =>
            connections.findIndex((item) => item === connector?.label) !== -1,
        ),
    },
  ];
};
