import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { FiltersTableName } from '../filters/filters.reducer';
import { TableNamesOfFilters } from 'components/table/table-filters-components/filter-renderer/filter-renderer';
import { SortOrder } from 'utils/sort-order/sort';
import {
  ASSET_ACTIVITY_PULL_REQUESTS,
  ASSET_DETAILS_BRANCHES_TABLE,
  ASSET_DETAILS_COMPLIANCE_TABLE,
  COMPLIANCE_MANUAL_POLICY_DETAILS_TABLE,
  COMPLIANCE_OVERVIEW_TABLE,
  COMPLIANCE_POLICY_DETAILS_TABLE,
  IDENTITY_DETAILS_ACCESS_TABLE,
  IDENTITY_DETAILS_ACTIVITY_TABLE,
  IDENTITY_DETAILS_ARTIFACTS_TABLE,
  IDENTITY_DETAILS_AUDIT_TRAIL_TABLE,
  IDENTITY_DETAILS_FOLDERS_TABLE,
  IDENTITY_DETAILS_JIT_LISTING_TABLE,
  IDENTITY_DETAILS_JIT_REQUESTS_TABLE,
  OPENSOURCE_DETAILS_REPOSITORIES_TABLE,
  OSSF_COMPLIANCE_TABLE,
  RECOMMENDATION_TEAM_DETAILS_TABLE,
  RECOMMENDATION_TEAM_MEMBER_DETAILS_TABLE,
  RECOMMENDATION_TEAM_MEMBERS_TABLE,
  RECOMMENDATION_TEAMS_TABLE,
  RECOMMENDATION_USER_DETAILS_TABLE,
  RECOMMENDATION_USERS_TABLE,
  RECOMMENDATIONS_STATUS_TABLE,
  REPORTING_MAIN_TABLE,
  TEAM_DETAILS_IDLE_USERS_TABLE,
  TEAM_DETAILS_MAPPINGS_TABLE,
  TEAM_DETAILS_RECOMMENDATIONS_TABLE,
  WATCH_HIDE_MAIN_TABLE,
} from 'constants/test-ids';
export type Filter = Record<
  string,
  string | boolean | string[] | undefined | number | any[]
>;
export interface Count {
  from: string;
  to: string;
}

export interface SearchParam {
  id: string;
  query: string;
}
export interface FilterColumnStore {
  previousColumn: string;
  previousTable: string;
  coordinates: { x: number; y: number };
  filteredColumn: string;
  tableFilterChips: Filter;
  applicableFilters: Filter;
  highlightColumns: { id: string; title: string }[];
  activeSortedField: string;
  activeSortedOrder: SortOrder;
  searchBy: SearchParam[];
  page: number;
  itemsPerPage: number;
  totalItems: number;
  detectionsCount: Count;
}

export interface SearchChip {
  id: string;
  title: string;
  query: string;
}

export type TablesFilterStore = Record<string, FilterColumnStore>;

const initialTableState: FilterColumnStore = {
  previousTable: '',
  previousColumn: '',
  coordinates: { x: 0, y: 0 },
  filteredColumn: '',
  tableFilterChips: {},
  applicableFilters: {},
  highlightColumns: [],
  activeSortedField: '',
  activeSortedOrder: SortOrder.Desc,
  searchBy: [],
  page: 1,
  itemsPerPage: 10,
  totalItems: 0,
  detectionsCount: {
    from: '0',
    to: '0',
  },
};

const assestsInitialState: FilterColumnStore = {
  ...initialTableState,
  activeSortedField: 'last_activity',
};

const dastInitialState: FilterColumnStore = {
  ...initialTableState,
};
const identitiesInitialState: FilterColumnStore = {
  ...initialTableState,
  activeSortedField: 'overprivilege_score',
};
const identityAccessInitialState: FilterColumnStore = {
  ...initialTableState,
  activeSortedField: 'permission_used',
  activeSortedOrder: SortOrder.Asc,
};
const selfAccessInitialState: FilterColumnStore = {
  ...initialTableState,
  activeSortedField: 'highest_permission',
  activeSortedOrder: SortOrder.Asc,
};
const branchesInitialState: FilterColumnStore = {
  ...initialTableState,
  activeSortedField: 'severity',
};
const teamsInitialState: FilterColumnStore = {
  ...initialTableState,
  activeSortedField: 'team_risk_score',
};
const alertsInitialState: FilterColumnStore = {
  ...initialTableState,
  activeSortedField: 'timestamp',
};
const identitiesAlertsInitialState: FilterColumnStore = {
  ...initialTableState,
  activeSortedField: 'detection',
};

const paginationTableState: FilterColumnStore = { ...initialTableState };

export const initialState: TablesFilterStore = {
  [FiltersTableName.ASSETS_REPOS_LIST]: assestsInitialState,
  [FiltersTableName.ASSETS_ARTIFACTS_LIST]: assestsInitialState,
  [TableNamesOfFilters.ASSETS_ACTIVITY]: assestsInitialState,
  [FiltersTableName.ASSET_DETAILS_ARTIFACTS]: initialTableState,
  [FiltersTableName.ASSET_DETAILS_SECRETS]: assestsInitialState,
  [FiltersTableName.ASSET_DETAILS_AUDITLOG]: initialTableState,
  [TableNamesOfFilters.ASSET_DETAILS_CONTAINERS]: assestsInitialState,
  [TableNamesOfFilters.ASSET_EXTERNAL_PACKAGES]: assestsInitialState,
  [TableNamesOfFilters.ASSETS_DETAILS_CONTRIBUTORS]: assestsInitialState,
  [TableNamesOfFilters.ASSET_DETAILS_TEAMS]: assestsInitialState,
  [TableNamesOfFilters.ASSETS_PIPELINE]: assestsInitialState,
  [FiltersTableName.IAC_FINDINGS_TABLE]: assestsInitialState,
  [TableNamesOfFilters.IDENTITIES_USERS_LIST]: identitiesInitialState,
  [FiltersTableName.IDENTITIES_ACCESS]: identityAccessInitialState,
  [FiltersTableName.SELF_ACCESS_TABLE]: selfAccessInitialState,
  [TableNamesOfFilters.IDENTITIES_ACTIVITY]: assestsInitialState,
  [FiltersTableName.ALERTS_LIST]: alertsInitialState,
  [FiltersTableName.IDENTITIES_ALERTS_LIST]: identitiesAlertsInitialState,
  [FiltersTableName.IDENTITIES_RECOMMENDATIONS]: assestsInitialState,
  [FiltersTableName.IDENTITIES_OSS]: assestsInitialState,
  [FiltersTableName.IDENTITIES_PIPELINE]: assestsInitialState,
  [TableNamesOfFilters.IDENTITIES_SECRETS_LIST]: assestsInitialState,
  [TableNamesOfFilters.IDENTITIES_DAST_LIST]: dastInitialState,
  [FiltersTableName.OPENSOURCE_REPOSITORIES_LIST]: assestsInitialState,
  [TableNamesOfFilters.OSS_ACCESS_REPOS]: assestsInitialState,
  [FiltersTableName.POLICIES_LIBRARY]: branchesInitialState,
  [FiltersTableName.POLICIES_CUSTOM_POLICIES]: branchesInitialState,
  [FiltersTableName.SAST_FINDINGS_TABLE]: assestsInitialState,
  [FiltersTableName.DETECTED_SECRETS_LIST]: assestsInitialState,
  [FiltersTableName.TEAMS_LIST]: teamsInitialState,
  [TableNamesOfFilters.TEAM_MEMBERS_TABLE]: assestsInitialState,
  [TableNamesOfFilters.TEAM_REPO_ACCESS]: assestsInitialState,
  [TableNamesOfFilters.EDIT_POLICY]: initialTableState,
  [FiltersTableName.TRIVY_PR_SCANS_TABLE]: initialTableState,
  [FiltersTableName.RECOMMENDATIONS_LIST]: initialTableState,
  [FiltersTableName.PR_SECRET_SCANS_TABLE]: initialTableState,
  [FiltersTableName.DAST_FINDINGS_TABLE]: assestsInitialState,
  [OPENSOURCE_DETAILS_REPOSITORIES_TABLE]: initialTableState,
  [TEAM_DETAILS_RECOMMENDATIONS_TABLE]: initialTableState,
  [TEAM_DETAILS_IDLE_USERS_TABLE]: initialTableState,
  [RECOMMENDATION_TEAMS_TABLE]: initialTableState,
  [ASSET_ACTIVITY_PULL_REQUESTS]: initialTableState,
  [FiltersTableName.ASSETS_NESTED_BRANCHES]: initialTableState,
  [COMPLIANCE_OVERVIEW_TABLE]: paginationTableState,
  [COMPLIANCE_MANUAL_POLICY_DETAILS_TABLE]: paginationTableState,
  [COMPLIANCE_POLICY_DETAILS_TABLE]: paginationTableState,
  [IDENTITY_DETAILS_ARTIFACTS_TABLE]: paginationTableState,
  [IDENTITY_DETAILS_ACTIVITY_TABLE]: paginationTableState,
  [IDENTITY_DETAILS_AUDIT_TRAIL_TABLE]: paginationTableState,
  [IDENTITY_DETAILS_FOLDERS_TABLE]: paginationTableState,
  [IDENTITY_DETAILS_ACCESS_TABLE]: paginationTableState,
  [FiltersTableName.ASSET_DETAILS_FOLDERS]: paginationTableState,
  [FiltersTableName.BRANCHES_LIST]: paginationTableState,
  [WATCH_HIDE_MAIN_TABLE]: paginationTableState,
  [TEAM_DETAILS_MAPPINGS_TABLE]: paginationTableState,
  [REPORTING_MAIN_TABLE]: paginationTableState,
  [RECOMMENDATIONS_STATUS_TABLE]: paginationTableState,
  [RECOMMENDATION_USER_DETAILS_TABLE]: paginationTableState,
  [RECOMMENDATION_USERS_TABLE]: paginationTableState,
  [RECOMMENDATION_TEAM_DETAILS_TABLE]: paginationTableState,
  [RECOMMENDATION_TEAM_MEMBER_DETAILS_TABLE]: paginationTableState,
  [RECOMMENDATION_TEAM_MEMBERS_TABLE]: paginationTableState,
  [OSSF_COMPLIANCE_TABLE]: paginationTableState,
  [ASSET_DETAILS_COMPLIANCE_TABLE]: paginationTableState,
  [ASSET_DETAILS_BRANCHES_TABLE]: paginationTableState,
  [IDENTITY_DETAILS_JIT_LISTING_TABLE]: paginationTableState,
  [IDENTITY_DETAILS_JIT_REQUESTS_TABLE]: paginationTableState,
};

export const selectedColumnSlice = createSlice({
  name: 'selected-column',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetSortState: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string }>,
    ) => {
      state[action.payload.table].activeSortedField =
        initialState[action.payload.table].activeSortedField;
      state[action.payload.table].activeSortedOrder =
        initialState[action.payload.table].activeSortedOrder;
    },
    setSelectedColumn: (
      state: TablesFilterStore,
      action: PayloadAction<{ column: string; table: string }>,
    ) => {
      state[action.payload.table].previousColumn = action.payload.column;
    },
    setSelectedTable: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string }>,
    ) => {
      state[action.payload.table].previousTable = action.payload.table;
    },
    setColumnCoordinates: (
      state: TablesFilterStore,
      action: PayloadAction<{
        coordinates: { x: number; y: number };
        table: string;
      }>,
    ) => {
      state[action.payload.table].coordinates = action.payload.coordinates;
    },
    setHighlightColumns: (
      state: TablesFilterStore,
      action: PayloadAction<{
        table: string;
        highlightColumns: { id: string; title: string }[];
      }>,
    ) => {
      state[action.payload.table].highlightColumns =
        action.payload.highlightColumns;
    },
    setFilterChips: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string; tableFilterChips: Filter }>,
    ) => {
      state[action.payload.table].tableFilterChips =
        action.payload.tableFilterChips;
    },
    setApplicableFilters: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string; applicableFilters: Filter }>,
    ) => {
      state[action.payload.table].applicableFilters =
        action.payload.applicableFilters;
    },
    setActiveSortedField: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string; activeSortedField: string }>,
    ) => {
      state[action.payload.table].activeSortedField =
        action.payload.activeSortedField;
    },
    setActiveSortedOrder: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string; activeSortedOrder: SortOrder }>,
    ) => {
      state[action.payload.table].activeSortedOrder =
        action.payload.activeSortedOrder;
    },
    setSearchBy: (
      state: TablesFilterStore,
      action: PayloadAction<{
        table: string;
        searchBy: { id: string; query: string }[];
      }>,
    ) => {
      state[action.payload.table].searchBy = action.payload.searchBy;
    },
    setTablePageNumber: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string; page: number }>,
    ) => {
      state[action.payload.table].page = action.payload.page;
    },
    setTableItemsPerPage: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string; itemsPerPage: number }>,
    ) => {
      state[action.payload.table].itemsPerPage = action.payload.itemsPerPage;
    },
    setTableTotalItems: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string; total: number }>,
    ) => {
      state[action.payload.table].totalItems = action.payload.total;
    },
    setDetectionsCount: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string; count: Count }>,
    ) => {
      state[action.payload.table].detectionsCount = action.payload.count;
    },
    resetDetectionsCount: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string }>,
    ) => {
      state[action.payload.table].detectionsCount =
        initialState[action.payload.table].detectionsCount;
    },
    addSearchChips: (
      state: TablesFilterStore,
      action: PayloadAction<{ table: string; searchChip: SearchChip }>,
    ) => {
      const table = action.payload.table;
      const { searchChips } = state[table].tableFilterChips;
      if (!searchChips || Array.isArray(searchChips))
        state[table].tableFilterChips = {
          ...state[table].tableFilterChips,
          searchChips: [
            ...(Array.isArray(searchChips) ? searchChips : []).filter(
              (srch) => srch.id !== action.payload.searchChip.id,
            ),
            action.payload.searchChip,
          ],
        };
    },
    addHighlighColumns: (
      state: TablesFilterStore,
      action: PayloadAction<{
        table: string;
        highlightColumns: { id: string; title: string }[];
      }>,
    ) => {
      state[action.payload.table].highlightColumns = [
        ...state[action.payload.table].highlightColumns,
        ...action.payload.highlightColumns,
      ];
    },
  },
});

export const {
  resetState,
  resetSortState,
  setFilterChips,
  setSelectedTable,
  setSelectedColumn,
  setHighlightColumns,
  setColumnCoordinates,
  setApplicableFilters,
  setActiveSortedField,
  setActiveSortedOrder,
  setSearchBy,
  setTableItemsPerPage,
  setTablePageNumber,
  setTableTotalItems,
  setDetectionsCount,
  resetDetectionsCount,
  addSearchChips,
  addHighlighColumns,
} = selectedColumnSlice.actions;

export const selectedColumnReducer = selectedColumnSlice.reducer;
