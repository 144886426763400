import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import { ReactComponent as CrossIcon } from 'assets/icons/cross-circle.svg';

interface Props {
  filterName: string | undefined;
  filterValue: string | boolean | string[] | undefined | number | any[];
  removeFilter: () => void;
  sortedColumn?: string | undefined;
}

export const FilterChip = ({
  filterName,
  filterValue,
  removeFilter,
  sortedColumn,
}: Props) => {
  const riskRatings = ['None', 'Low', 'Medium', 'High', 'Critical'];
  const severities = ['Informational', 'Low', 'Medium', 'High', 'Critical'];
  const renderValue = () => {
    if (filterName === 'Risk Rating') {
      const value = Number(filterValue);
      if (value === 0) return riskRatings[value];
      return riskRatings[value - 1] || filterValue;
    }
    if (filterName === 'Severity') {
      const value = Number(filterValue);
      return severities[value - 1] || filterValue;
    }
    if (filterName === 'Identity Status') {
      if (filterValue === 'true') return 'External';
      if (filterValue === 'false') return 'Internal';
    }
    if (filterName === 'Account status') {
      if (filterValue === 'true') return 'Active';
      if (filterValue === 'false') return 'Inactive';
    }
    if (filterName === 'Language' && filterValue === '') return 'None';
    if (filterName === 'Detections Count')
      return filterValue?.toString().includes('-0')
        ? `From ${filterValue.toString().split('-')[0]}`
        : `From ${filterValue?.toString().replace('-', ' to ')}`;
    return filterValue;
  };
  return (
    <Box
      sx={{
        minWidth: '0px',
        minHeight: '42px',
        borderRadius: '5px',
        border: `1px solid ${Colors.chipBorder}`,
        padding: '5px 10px',
        gap: '10px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        backgroundColor: `${
          filterName === 'Sort By' ? Colors.tableHeader : 'transparent'
        }`,
      }}
    >
      <Box sx={{ marginRight: '10px' }}>
        <Typography
          sx={{
            color: `${
              filterName === 'Sort By' ? Colors.darkGray : Colors.binge
            }`,
            fontFamily: fontSecondary,
            fontSize: FontSizes.fontSize12,
            lineHeight: '16px',
            fontWeight: 400,
          }}
        >
          {filterName}
        </Typography>
        <Typography
          sx={{
            color: Colors.blackPearl,
            fontFamily: fontSecondary,
            fontSize: FontSizes.fontSize12,
            lineHeight: '16px',
            fontWeight: 600,
          }}
        >
          {sortedColumn ? (
            <span style={{ color: Colors.binge }}>{sortedColumn}: </span>
          ) : (
            ''
          )}
          {renderValue()}
        </Typography>
      </Box>
      <Button
        sx={{
          minWidth: '0px',
          padding: '0px',
        }}
        onClick={removeFilter}
      >
        <CrossIcon
          style={{
            width: '16px',
            height: '16px',
            color: Colors.blackPearl,
          }}
        />
      </Button>
    </Box>
  );
};
